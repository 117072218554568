<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="企业账号">
            <el-input placeholder="请输入" v-model="form.enterpriseAccount" clearable></el-input>
          </el-form-item>
          <el-form-item label="应用名称">
            <el-input placeholder="请输入" v-model="form.appName" clearable></el-input>
          </el-form-item>
          <el-form-item label="申请时间">
            <el-date-picker v-model="queryTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
          </el-form-item>
        </el-form>
        <el-tabs v-model="appStatus" type="card" @tab-click="handleClickTab">
          <el-tab-pane label="全部" name="-1"></el-tab-pane>
          <el-tab-pane label="待审批" name="3"></el-tab-pane>
          <el-tab-pane label="已审批" name="0"></el-tab-pane>
          <el-tab-pane label="已拒绝" name="4"></el-tab-pane>
        </el-tabs>
      </template>
      <template #default>
        <el-table ref="table" :data="tableData" v-loading="tableLoading" border stripe height="100%">
          <el-table-column prop="enterpriseAccount" label="企业账号" align="center" min-width="150" />
          <el-table-column prop="appName" label="应用名称" align="center" min-width="150" />
          <el-table-column prop="logo" label="应用图标" align="center" min-width="100">
            <template slot-scope="scope">
              <el-image v-if="scope.row.logo" :src="scope.row.logo" :preview-src-list="[scope.row.logo]" style="max-width: 70px" />
            </template>
          </el-table-column>
          <el-table-column prop="" label="状态" align="center" min-width="100">
            <template slot-scope="scope">
              {{ getStatus(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column prop="appCategory" label="应用类目" align="center" min-width="150" />
          <el-table-column prop="appLabel" label="应用标签" align="center" min-width="150" />
          <el-table-column prop="applyMpTime" label="申请时间" align="center" min-width="150" />
          <el-table-column prop="userMpAccount" label="申请账号" align="center" min-width="100" />
          <el-table-column prop="applyMpTime" label="审核时间" align="center" min-width="150" />
          <el-table-column prop="applyMpAuditUser" label="审核账号" align="center" min-width="100" />
          <el-table-column label="操作" align="center" min-width="120" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)"> 查看 </el-button>
              <el-button type="text" v-if="scope.row.appStatus==3" @click="handlePass(scope.row)"> 审批 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.pageSize" :current-page="page.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>
    <detail-modal :show.sync="showAdd" :detail="curRow" @close="closeModal" />
  </div>
</template>
<script>
import detailModal from './detailModal'
import api from '@/api/operate/appList'
export default {
  components: { detailModal },
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      showAdd: false,
      curRow: null,
      queryTime: [],
      appStatus: '-1',
    }
  },
  created() {
    this.query()
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
      }
      if (this.queryTime && this.queryTime.length == 2) {
        params.applyQueryStartTime = this.queryTime[0]
        params.applyQueryEndTime = this.queryTime[1]
      } else {
        params.applyQueryStartTime = ''
        params.applyQueryEndTime = ''
      }
      this.tableLoading = true
      api
        .query(params)
        .then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.page.pageNum = 1
      this.query()
    },
    handleCurrentChange(val) {
      this.page.pageNum = val
      this.query()
    },
    handleAdd() {
      this.curRow = null
      this.showAdd = true
    },
    handleDetail(row) {
      this.curRow = _.cloneDeep(row)
      this.showAdd = true
    },
    handlePass(row) {
      this.$confirm('是否审批', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '拒绝',
        type: 'warning',
      })
        .then(() => {
          api.pass(row).then(() => {
            this.$msg.success('操作成功')
            this.query()
          })
        })
        .catch(() => {
          api.refuse(row).then(() => {
            this.$msg.success('操作成功')
            this.query()
          })
        })
    },
    closeModal() {
      this.showAdd = false
      this.query()
    },
    getStatus({ appStatus }) {
      return {
        0: '已审批',
        1: '上架',
        2: '下架',
        3: '待审批',
        4: '已拒绝',
      }[appStatus]
    },
    handleClickTab() {
      if (this.appStatus != '-1') {
        this.form.appStatus = this.appStatus
      } else {
        this.form.appStatus = ''
      }
      this.query()
    },
  },
}
</script>
