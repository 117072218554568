<!-- 应用详情 -->
<template>
  <el-dialog title="详情" fullscreen :visible="show" @close="close">
    <div class="h100 flex">
      <el-form label-width="100px" style="width: 350px" class="h100 border-right-line overflow-auto">
        <el-form-item label="应用名称">{{ form.appName }}</el-form-item>
        <el-form-item label="应用图标">
          <el-image v-if="form.logo" :src="form.logo" :preview-src-list="[form.logo]" style="max-width: 70px" />
        </el-form-item>
        <el-form-item label="企业账号">{{ form.enterpriseAccount }}</el-form-item>
        <el-form-item label="申请账号">{{ form.userMpAccount }}</el-form-item>
        <el-form-item label="申请时间">{{ form.applyMpTime }}</el-form-item>
        <el-form-item label="审核账号">{{ form.applyMpAuditUser }}</el-form-item>
        <el-form-item label="审核时间">{{ form.applyMpTime }}</el-form-item>
        <el-form-item label="应用状态">{{ getStatus() }}</el-form-item>
        <el-form-item label="应用类目">{{ form.appCategory }}</el-form-item>
        <el-form-item label="应用标签">{{ form.appLabel }}</el-form-item>
      </el-form>
      <div class="flex-1 h100 pl10 font12" style="width: 0">
        <common-layout class="h100">
          <template #header>
            <div class="flex-b border-bottom-line pb10">
              <div>应用描述</div>
              <div>
                <span>应用版本</span>
                <el-select v-model="appVersion" @change="handleChangeVersion" class="ml10">
                  <el-option v-for="(item, index) in form.details" :key="index" :value="item.appVersion" :label="item.appVersion"></el-option>
                </el-select>
              </div>
            </div>
          </template>
          <template>
            <div v-html="appDesc" class="mt10"></div>
          </template>
        </common-layout>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api/operate/appList'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      appVersion: '',
      appDesc: '',
    }
  },

  watch: {
    show(val) {
      if (val) {
        this.getDetail()
      } else {
        this.form = {}
      }
    },
  },
  methods: {
    getDetail() {
      api.detail(this.detail).then((res) => {
        this.form = res.data
        if (this.form.details.length) {
          this.appVersion = this.form.details[0].appVersion
          this.appDesc = this.form.details[0].appDesc
        }
      })
    },
    close() {
      this.$emit('update:show', false)
    },
    getStatus() {
      return {
        0: '已审批',
        1: '上架',
        2: '下架',
        3: '待审批',
        4: '已拒绝',
      }[this.form.appStatus]
    },
    handleChangeVersion(val) {
      if (!val) {
        this.appDesc = ''
        return
      }
      this.appDesc = this.form.details.filter((i) => i.appVersion == val)[0].appDesc
    },
  },
}
</script>
<style lang="scss" scoped></style>
